
import React, { useState, useEffect ,useRef} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { generateOTP, verifyOTP, postErrorLog } from '../Services/api';
import Loader from './Loader';
import jwtDecode from "jwt-decode";
const UserLogin = () => {
  const [otp, setOtp] = useState<string>('');
  const [firstTimeLoad, setfirstTimeLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [otpLockTime, setOtpLockTime] = useState<number | null>(null);

  const [searchParams] = useSearchParams();
  const emailId: any | null = searchParams.get('emailId');
  const assessmentId: any | null = searchParams.get('assessmentId');
  const navigate = useNavigate();
  const saveLogToLocalStorage = (logMessage: string) => {
    const logs = JSON.parse(localStorage.getItem('logs') ?? '[]'); // Get existing logs or initialize as an empty array
    logs.push(logMessage); // Add new log message
    localStorage.setItem('logs', JSON.stringify(logs)); // Save updated logs array back to local storage
    console.log('Log saved to local storage');
};
  // PS_Otp_04: SQ_Otp_1.4 - useEffect

    useEffect(() => {
    let timer: NodeJS.Timeout;
    saveLogToLocalStorage('useEffect: userlogin.tsx')
    if (otpLockTime) {
      timer = setInterval(() => {
        if (Date.now() >= otpLockTime!) {
          setDisableButtons(false);
          setOtpLockTime(null);
          setError(''); // Clear the error message when lockout period ends
          localStorage.removeItem('otpLockTime');
          saveLogToLocalStorage('otpLockTime')
          clearInterval(timer);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpLockTime]);
      // Helper function implementations

  // PS_Otp_05 - PS_Otp_14
  const initiateOtpGeneration = async () => {
    let OTPErr =false;
    if (!emailId || !assessmentId ) {
      return;
    }
    try {
      setLoading(true);
      setError('');
      setOtp('');
      saveLogToLocalStorage('initiateOtpGeneration') 
      const storedOtpLockTime = localStorage.getItem('otpLockTime');
      if (storedOtpLockTime) {
        const lockTime = parseInt(storedOtpLockTime, 10);
        if (!isNaN(lockTime) && Date.now() < lockTime) {
          console.log("storedOtpLockTime");
          setOtpLockTime(lockTime);
          setDisableButtons(true);
          setError('Limit Exceeded. Please try again after 5 minutes.');
          saveLogToLocalStorage('initiateOtpGeneration:Limit Exceeded. Please try again after 5 minutes.')
          return; // Exit early, within lockout period
        }
      }
      saveLogToLocalStorage('generateOTP')
      await generateOTP(emailId, assessmentId);   
    } catch (error: any) {
      OTPErr= true
      const errorData = {
        errorFunction: "initiateOtpGeneration",
        errorMessage: error.message,
        FileName: "UserLogin.tsx"
      };
            // PS_Otp_11 & PS_Otp_12
      await postErrorLog(errorData);
      saveLogToLocalStorage('Failed to generate OTP. Please try again.')
      setError('Failed to generate OTP. Please try again.');
    } finally {
      saveLogToLocalStorage('generateOTP: finally')
      setLoading(false);
      setfirstTimeLoad(false);
      if(!OTPErr){
        localStorage.setItem('OTPGenerated', 'true');
      }
    }
  };

  //PS_Otp_16 - PS_Otp_27
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // PS_Otp_17
    if (!otp) {
      setError('Please enter the OTP.'); // PS_Otp_27
      return;
    }
    saveLogToLocalStorage('handleSubmit')
  try {
    setLoading(true); // PS_Otp_20
    setError(''); // Resetting the existing error messages
      // PS_Otp_21 & PS_Otp_22
      const verificationResult = await verifyOTP(otp, assessmentId!, emailId!);
    const responseData = verificationResult.data as { 
      success: boolean; 
      status: string; 
      message: string; 
      otp: string;
      Token: string;
    };

      // PS_Otp_23
    if (responseData.success && responseData.message === "Successfully authenticated") {
      console.log("Successfully authenticated");
      saveLogToLocalStorage('Successfully authenticated')
      try {
        // Decode the Base64 token
        const decodedBase64Token = atob(responseData.Token);

        // Split the decoded string
        const [jwtToken, assessmentId, emailId] = decodedBase64Token.split(':');


        if (assessmentId === assessmentId && emailId === emailId) {
          saveLogToLocalStorage('handleSubmit: /download')
          console.log("/download");
          navigate('/download', { 
            state: { 
              emailId, 
              assessmentId,
              token: responseData.Token
            } 
          });
        } else {
          saveLogToLocalStorage('Authentication failed. Token mismatch.')
          setError('Authentication failed. Token mismatch.');
        }


      } catch (decodeError) {
        saveLogToLocalStorage('Authentication failed. Invalid token format.')
        setError('Authentication failed. Invalid token format.');
      }
    } else if (responseData.message === "Limit Exceeded. Please try again after 5 minutes.") {
      saveLogToLocalStorage('Limit Exceeded. Please try again after 5 minutes.')
      const lockTime = Date.now() + 5 * 60 * 1000;
      setOtpLockTime(lockTime);
    // otplock(lockTime)
      setDisableButtons(true);
      localStorage.setItem('otpLockTime', lockTime.toString());
      setError(responseData.message);
    } else {
      saveLogToLocalStorage('responseData.message')
      setError(responseData.message);
    }
  } catch (error: any) {
    let errorData: object = {
      errorFunction: "handleSubmit",
      errorMessage: error.message,
      FileName: "UserLogin.tsx"
    }
          // PS_Otp_11 & PS_Otp_12
    await postErrorLog(errorData);
    saveLogToLocalStorage('An error occurred during OTP verification.')
    setError('An error occurred during OTP verification.');
  } finally {
    saveLogToLocalStorage('handleSubmit: finally.')
    setLoading(false); // PS_Otp_26
  }
};


  // PS_Otp_28: SQ_Otp_1.25 - Render Method
  return (
    <>
      <Loader isLoading={loading} />
      <div className="container-fluid">
        <div className="row h-100 login-page">
          <div className="col-6">
            <div className="d-flex align-items-center">
              <img src="images/logo.svg" className="logo-img me-2" alt="site logo" />
              <span className="font-medium font-18 primary-text-color">Win Remedy</span>
            </div>

            <div className="px-5 verification-code-section">
              <h3 className="primary-text-color font-40 font-semibold">Verify code</h3>
              <p className="font-16 font-regular mt-3 grey-text-color-dark">
              Here, you can download your installer file once you have been authenticated and the authentication code has been sent to your email.
              </p>
              <>{firstTimeLoad? 
             <button
                  className="primary-btn popup-btn-v2 ms-2 mt-5"
                  type="button"
                  onClick={()=>initiateOtpGeneration()}
                >
                  Generate OTP
                </button>
              :<>
              <div className="mt-5 position-relative">
                <div className="form-verify-code">
                  <input 
                    id="Password" 
                    type={showPassword ? "text" : "password"} 
                    className="form-control custom-password-field"
                    placeholder=""
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <label className="form-label font-14 font-regular custom-floating-postion" htmlFor="floating-code">
                    Enter Code
                  </label>
                  <img 
                    src={showPassword ? "images/pass-hidden.svg" : "images/pass-open.svg"} 
                    className="pass-eye" 
                    alt={showPassword ? "hide password" : "show password"}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
                {error && <span className="required font-11 font-regular d-block">{error}</span>}
              </div>

              <p className="font-14 font-regular primary-text-color text-end mt-3 mb-4 pb-2">
                Didn't receive a code?  
                {disableButtons ? (
                  <span className="disabled-link"> Resend</span>
                ) : (
                  <a href="#" onClick={()=>initiateOtpGeneration()}> Resend</a>
                )}
              </p>

              {disableButtons || loading ? (
                <button
                  className="primary-button popup-btn-v2 ms-2"
                  type="button"
                  disabled
                >
                  Verify
                </button>
              ) : (
                <button
                  className="primary-btn popup-btn-v2 ms-2"
                  type="button"
                  onClick={handleSubmit}
                >
                  Verify
                </button>
              )}
            </>}</>
            </div>
          </div>

          <div className="col-6 banner-right">
            <img 
              src="images/welcome-banner.png" 
              className="login-banner-img" 
              alt="login page banner image"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLogin;