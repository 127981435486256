
// UrlDownload.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // (PS_URL_04) Import useNavigate and useLocation
import { fetchUrlTypes, downloadUrlType, postErrorLog } from '../Services/api'; // Import API services
import { AxiosResponse } from 'axios'; // Import AxiosResponse for type checking
import NavHeader from './NavHeader'; // Importing NavHeader component
import JSZip from 'jszip'; // Importing JSZip for handling ZIP files
import { saveAs } from 'file-saver'; // Importing saveAs for saving files
import Loader from './Loader'; // Importing Loader component for loading states

// Interface for URL Types
interface UrlType {
  url_id: number;
  download_url: string;
}

// Interface for Download Requests
interface DownloadRequest {
  type: string;
  bitConfig: number;
  userName: string;
  assessmentID: string;
  mailId : string
}

// UrlDownload component
const UrlDownload: React.FC = () => {
  // Initializing state variables (PS_URL_07)
  const [urlTypes, setUrlTypes] = useState<UrlType[]>([]);
  const [selectedUrlType, setSelectedUrlType] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false); // Assuming auth check is done below
  const [assessmentId, setAssessmentId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');

  const navigate = useNavigate(); // (PS_URL_05)
  const location = useLocation(); // (PS_URL_06)

  // Authentication and data fetching useEffect
  useEffect(() => {
    if(localStorage.getItem('OTPGenerated')=='true'){

    let auth = false;
    // Check for location state
    if (location.state) {
      if (typeof location.state === 'string') {
        setAssessmentId(location.state);
        localStorage.setItem('assessmentId', location.state);
        localStorage.removeItem('emailId'); // Remove email if only assessmentId is available
        auth = true;
      } else {
        const { emailId, assessmentId } = location.state as { emailId: string; assessmentId: string };
        if (emailId) localStorage.setItem('emailId', emailId);
        if (assessmentId) localStorage.setItem('assessmentId', assessmentId);
        setEmail(emailId || '');
        setAssessmentId(assessmentId);
        auth = !!(emailId && assessmentId);
        auth = true;
      }
    } else {
      // Check local storage for persisted assessmentId and emailId
      const storedEmailId = localStorage.getItem('emailId');
      const storedAssessmentId = localStorage.getItem('assessmentId');
      setEmail(storedEmailId || '');
      setAssessmentId(storedAssessmentId || '');
      auth = !!(storedEmailId && storedAssessmentId);
    }

    setIsAuthorized(auth); // Set authorization based on the presence of assessmentId and emailId

    if (auth) {
      fetchUrlTypesData(); // Fetch URL types data
    }
  }
  }, [location]);
  // Function to fetch URL Types (PS_URL_15 - PS_URL_22)
  async function fetchUrlTypesData() {
    setIsLoading(true);
    try {
      const response:AxiosResponse = await fetchUrlTypes();
      if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
        setUrlTypes(response.data);
      } else {
        let errorData:object = {
          errorFunction: "fetchUrlTypesData",
          errorMessage: "No data received from API, using default values",
          errorFileName: "UrlDownload.tsx"
      }
      
    // PS_Otp_11 & PS_Otp_12
    await postErrorLog(errorData);
      }
    }  catch (error:any) {
        let errorData:object = {
            errorFunction: "fetchUrlTypesData",
            errorMessage: error.message,
            FileName: "UrlDownload.tsx"
        }
        
      // PS_Otp_11 & PS_Otp_12
      await postErrorLog(errorData);
      // await postErrorLog(errorData); // make sure this function is defined somewhere
      setErrorMessage('An error occurred while fetching URL types.');
    } finally {
      setIsLoading(false);
    }
  }

  // Function to handle a download request (PS_URL_27 - PS_URL_53)
  async function handleDownload(urlType: UrlType): Promise<void> {
    setIsLoading(true);
    try {
      const match = urlType.download_url.match(/win_remedy\.(\w+)\((\d+) bit\)/);
      if (!match) {
        throw new Error('Invalid download URL format');
      }
      const [, fileExtension, config] = match;
  
      let type: string;
      if (fileExtension.toLowerCase() === 'exe') {
        type = 'exe';
      } else if (fileExtension.toLowerCase() === 'msi') {
        type = 'msi';
      } else {
        throw new Error('Unsupported file type');
      }
  
      const downloadRequest: DownloadRequest = {
        type,
        bitConfig: parseInt(config),
        userName : userName,
        mailId: email, // Assuming email is defined in the component state
        assessmentID: assessmentId // Assuming assessmentId is defined in the component state
      };
  
  
      const response: AxiosResponse<ArrayBuffer> = await downloadUrlType(downloadRequest);
  
      const blob = new Blob([response.data], { type: 'application/zip' });
  
      try {
        const zip = await JSZip.loadAsync(blob);
  
        const expectedFiles = [
          { name: 'FinalConsoleApp.dll', expectedSize: 1024 }, // 1 KB
          { name: 'FinalConsoleApp.exe', expectedSize: 65685504 }, // 64,146 KB
          { name: 'FinalConsoleApp.pdb', expectedSize: 14336 }, // 14 KB
          { name: `win_remedy.${type}`, expectedSize: null } // Size unknown, just check presence
        ];
  
        const warnings: string[] = [];
        const foundFiles: string[] = [];
  
        zip.forEach((relativePath, zipEntry) => {
          if (!zipEntry.dir) {
            foundFiles.push(relativePath.toLowerCase());
            zipEntry.async('blob').then((fileBlob) => {
              
              const expectedFile = expectedFiles.find(f => f.name.toLowerCase() === relativePath.toLowerCase());
              if (expectedFile) {
                if (expectedFile.expectedSize && fileBlob.size !== expectedFile.expectedSize) {
                  warnings.push(`${relativePath} size mismatch. Expected: ${expectedFile.expectedSize} bytes, Actual: ${fileBlob.size} bytes`);
                }
              } else {
                warnings.push(`Unexpected file in zip: ${relativePath}`);
              }
            });
          }
        });
  
        const missingFiles = expectedFiles.filter(file => !foundFiles.includes(file.name.toLowerCase()));
        missingFiles.forEach(file => {
          warnings.push(`Missing expected file: ${file.name}`);
        });
  
        if (warnings.length > 0) {
          console.warn('Warnings:', warnings);
          // You might want to show these warnings to the user
          setErrorMessage(`Warnings found in zip contents: ${warnings.join(', ')}`);
        }
  
      } catch (zipError) {
        console.error('Error inspecting zip contents:', zipError);
        setErrorMessage('Error inspecting zip contents. The file might be corrupted.');
      }
  
      // Attempt to save the file
      try {
        saveAs(blob, `WinRemedy_${type}_${config}bit.zip`);
      } catch (saveError) {
        console.error('Error saving file:', saveError);
        // Fallback to manual download if saveAs fails
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `WinRemedy_${type}_${config}bit.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
  
    } catch (error: any) {
      console.error('Download error:', error);
      let errorData:object = {
        errorFunction: "fetchUrlTypesData",
        errorMessage: error.message,
        FileName: "UrlDownload.tsx"
    }
    
  await postErrorLog(errorData);
    } finally {
      setIsLoading(false);
    }
  }

  // Provide file format display function (PS_URL_54)
  const formatUrlTypeDisplay = (urlType: UrlType) => {
    return urlType.download_url;
  };
//PS_URL_47 - PS_URL_54
const handleUserGuide = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault(); // Prevent the default link behavior

  // Open the PDF in a new tab
  window.open('/pdf/Win11 - UserGuide.pdf', '_blank');
};

  // Handle unauthorized state (PS_URL_13)
  if (!isAuthorized) {
    return (
      // Render unauthorized state
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="error-page-height h-100vh"></div>
            <div className="position-absolute top-50 start-50 translate-middle text-center">
                <img src="/images/401-icon.svg" alt="401 Error" />
                <p className="font-bold font-32 text-black">Unauthorized Access</p>
                <p className="font-bold font-16 text-black mb-1">
                  Sorry, you don't have the necessary permissions to access this
                  page or resource.
                </p>
                <p className="font-bold font-16 text-black mb-5">
                  Please ensure you are logged in with the correct credentials and
                  try again.
                </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
    <NavHeader/>
    <Loader isLoading={isLoading} />
      <div className="container-fluid custom-container">
        <div className="d-flex align-items-center justify-content-center download-section">
          <img src="images/logo.svg" className="logo" alt="logo" />
          <p className="download-title font-60 font-light secondary-text-color-dark">
            Download <span className="font-regular">Win Remedy</span>
          </p>
          <p className="font-24 font-light secondary-text-color-dark mt-3 mb-5">
            Available in .msi & .exe for 64-bit and 32-bit.
          </p>
          
          <div className="download-option-dropdown position-relative">
          <button 
  type="button" 
  className="primary-btn position-relative font-14"
  onClick={() => setShowDropdown(!showDropdown)}
>
  {selectedUrlType || 'Download Now'}
  <span className="custom-btn-border"></span>
  <img src="images/dropdown arrow white.svg" className="dropdown arrow white" alt="dropdown arrow white" />
</button>

           {showDropdown && (
  <ul className="dropdown-menu dropdown-menu-end border-0 dd-position show">
    {urlTypes.map((urlType) => (
      <li key={urlType.url_id} onClick={() => {
        setSelectedUrlType(urlType.download_url);
        setErrorMessage('');
        setShowDropdown(false);
        handleDownload(urlType);
      }}>
        {formatUrlTypeDisplay(urlType)}
      </li>
    ))}
  </ul>
)}
          </div>
          <div className="mt-4">
            <img src="images/info icon.svg" className="info-icon" alt="info icon" />
            <span className="font-12 font-regular grey-text-color-light ms-2">
              Want to know how to use Win Remedy and it's features? <a href="#" onClick={handleUserGuide}>User Guide</a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UrlDownload;